import { IPersonCard } from "../../components/PersonCard/PersonCard"

const peopleData: IPersonCard[] = [
  {
    image: "",
    title: "Head of Sales",
    name: "Adam Matusiak",
  },
  {
    image: "",
    title: "Project Manager/ Scrum Master",
    name: "Paweł Wywijas",
  },
  {
    image: "",
    title: "UX/UI Designer/ Graphic",
    name: "Natalia Kilian",
  },
  {
    image: "",
    title: "Tech Lead / Architect",
    name: "Michał Kapłon",
  },
  {
    image: "",
    title: "Tech Lead / Architect",
    name: "Wojtek Zaborowski",
  },
  {
    image: "",
    title: "Java Developer",
    name: "Jacek Andrzejczyk",
  },
  {
    image: "",
    title: "Embedded Developer",
    name: "Dawid Śliwa",
  },
  {
    image: "",
    title: "Java Developer",
    name: "Piotr Michałkiewicz",
  },
  {
    image: "",
    title: "Java Developer",
    name: "Maksymilian Szczypkowski",
  },
]

export default peopleData

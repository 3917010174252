import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./styles.css";

interface ICarouselCard {
  image: any;
  title: string;
}

const CarouselCard = ({ title, image }: ICarouselCard) => {
  console.log(image);

  return (
    <div className="carousel-card__container">
      <GatsbyImage
        image={image}
        alt={`${title} picture`}
        className="carousel-card__image"
        objectFit="contain"
      />
      <p className="carousel-card__title">{title}</p>
    </div>
  );
};

export default CarouselCard;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const usePeopleGridImages = () => {
  const peopleImages = useStaticQuery(graphql`
    query peopleImagesQuery {
      allFile(
        filter: { name: { in: ["1", "2", "3", "4", "5", "6", "7", "8", "9"] } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return peopleImages;
};

export default usePeopleGridImages;

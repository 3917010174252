import React from "react";
import "./styles.css";
import CarouselCard from "../CarouselCard";
import useCarouselImages from "../../modules/PeopleGrid/useCarouselCards";

const cardsTitles = [
  "Frontend developer",
  "Legacy Fighter",
  "UX/UI designer",
  "Backend developer",
  "Tech Lead",
];

const Carousel = () => {
  const carouselImages = useCarouselImages();

  console.log(carouselImages);

  return (
    <div className="carousel_container">
      <div className="carousel">
        {cardsTitles.map((title, idx) => (
          <div className="carousel__face" key={title}>
            <CarouselCard
              image={
                carouselImages?.allFile?.edges[idx].node?.childImageSharp
                  ?.gatsbyImageData
              }
              title={title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

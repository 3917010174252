import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./styles.css";

interface IPersonCard {
  image: any;
  title: string;
  name: string;
}

const PersonCard = ({ image, title, name }: IPersonCard) => (
  <div className="person-card__container">
    <GatsbyImage
      className="person-card__image"
      image={image}
      alt={name + " picture"}
      objectFit="cover"
    />
    <h3>{title}</h3>
    <p>{name}</p>
  </div>
);

export type { IPersonCard };
export default PersonCard;

import { Color } from "../../global/styles";

interface ICTA extends IStyledCTA {
  to: string;
  iconColor?: Color;
  iconWidth?: number;
  iconHeight?: number;
  target?: ITarget;
  external?: boolean;
  ariaLabel?: string;
  activeStyles?: {};
  anchor?: boolean;
}

type ITarget = "_blank" | "_parent" | "_self" | "_top";

interface IStyledCTA {
  variant?: CTAVariant;
  iconRevertX?: boolean;
  Icon?: any;
}

enum CTAVariant {
  nav = "nav",
  gradient = "gradient",
  simple = "simple",
  button = "button",
  buttonOutlined = "buttonOutlined",
  icon = "icon",
  breadcrumb = "breadcrumb",
}

export { ICTA, IStyledCTA, CTAVariant };

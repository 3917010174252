import React, { PropsWithChildren } from "react";
import StyledCTA from "./CTA.styles";
import { CTAVariant, ICTA } from "./CTA.types";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
const CTA = ({
  children,
  to,
  variant,
  Icon,
  iconColor,
  iconHeight,
  iconWidth,
  iconRevertX,
  target,
  external,
  ariaLabel,
  activeStyles,
  anchor,
}: PropsWithChildren<ICTA>) => {
  return (
    <div variant={variant} iconRevertX={iconRevertX} className="cta__container">
      {external === true ? (
        <a
          href={to}
          target={target || "_self"}
          rel={target === "_blank" ? "noopener noreferrer" : ""}
          aria-label={ariaLabel}
        >
          <span>
            {children}{" "}
            {variant === CTAVariant.gradient && (
              <div className="cta__underline" />
            )}
          </span>
          {Icon && (
            <div className="cta__right-icon">
              <Icon
                SVGIcon={Icon}
                width={iconWidth || 40}
                height={iconHeight || 40}
                color={iconColor || "white"}
              />
            </div>
          )}
        </a>
      ) : anchor ? (
        <AnchorLink to={to}>
          <span>
            {children}{" "}
            {variant === CTAVariant.gradient && (
              <div className="cta__underline" />
            )}
          </span>
          {Icon && (
            <div className="cta__right-icon">
              <Icon
                SVGIcon={Icon}
                width={iconWidth || 40}
                height={iconHeight || 40}
                color={iconColor || "white"}
              />
            </div>
          )}
        </AnchorLink>
      ) : (
        <Link
          to={to}
          target={target || "_self"}
          rel={target === "_blank" ? "noopener noreferrer" : ""}
          activeStyle={activeStyles}
        >
          <span>
            {children}{" "}
            {variant === CTAVariant.gradient && (
              <div className="cta__underline" />
            )}
          </span>
          {Icon && (
            <div className="cta__right-icon">
              <Icon
                SVGIcon={Icon}
                width={iconWidth || 40}
                height={iconHeight || 40}
                color={iconColor || "white"}
              />
            </div>
          )}
        </Link>
      )}
    </div>
  );
};

export default CTA;

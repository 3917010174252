import React from "react";
import Module from "../../components/Module";
import PersonCard from "../../components/PersonCard/PersonCard";
import peopleData from "./peopleData";
import usePeopleGridImages from "./usePeopleGridImages";
import "./styles.css";

const PeopleGrid = () => {
  const peopleImages = usePeopleGridImages();

  console.log(peopleImages);

  return (
    <Module>
      <h2>Who will you work with?</h2>
      <p className="grid-text">
        We want you to see who you will actually create your product with,
        because we believe that the excitement and relationships within the team
        result in the success of the project! We are a group of passionate
        people who enjoy what they do.
      </p>
      <div className="people-grid__container">
        {peopleData.map((card, idx) => (
          <PersonCard
            key={card.name}
            {...card}
            image={
              peopleImages?.allFile?.edges[idx].node?.childImageSharp
                ?.gatsbyImageData
            }
          />
        ))}
      </div>
    </Module>
  );
};

export default PeopleGrid;

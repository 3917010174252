import React, { PropsWithChildren } from "react";
import "./styles.css";

const FooterModule = ({ children }: PropsWithChildren<{}>) => {
  return (
    <section className="footer-module__container">
      <footer className="footer-module__content">{children}</footer>
    </section>
  );
};

export default FooterModule;

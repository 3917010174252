import React from "react"

const ArrowDownIconSVG = () => {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5858 31.4142C14.3668 32.1953 15.6332 32.1953 16.4142 31.4142L29.1421 18.6863C29.9232 17.9052 29.9232 16.6389 29.1421 15.8579C28.3611 15.0768 27.0948 15.0768 26.3137 15.8579L15 27.1716L3.68629 15.8579C2.90524 15.0768 1.63891 15.0768 0.857865 15.8579C0.0768163 16.6389 0.0768164 17.9052 0.857865 18.6863L13.5858 31.4142ZM13 8.74231e-08L13 30L17 30L17 -8.74231e-08L13 8.74231e-08Z"
        fill="#F8F8F8"
      />
    </svg>
  )
}

export default ArrowDownIconSVG

import * as React from "react"
import Landing from "./modules/Landing/Landing"
import PeopleGrid from "./modules/PeopleGrid/PeopleGrid"
import Footer from "./modules/Footer/Footer"

const IndexMeetYourTeamPage = () => {
  return (
    <>
      <Landing />
      <PeopleGrid />
      <Footer />
    </>
  )
}

export default IndexMeetYourTeamPage

export const Head = () => {
  return (
    <>
      <title>{`Oakfusion - Meet Your Team`}</title>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#FE8041" />
      <meta name="title" content="Oakfusion - Meet Your Team" />
      <meta
        name="description"
        content="OakFusion - Your reliable software house for creating innovative and efficient team collaboration solutions. Meet and manage your team with ease and enhance productivity."
      />
      <meta name="keywords" content="" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="" />
      <meta property="og:title" content="Oakfusion - Meet Your Team" />

      <meta
        property="og:description"
        content="OakFusion - Your reliable software house for creating innovative and efficient team collaboration solutions. Meet and manage your team with ease and enhance productivity."
      />

      <meta property="og:image" content="" />

      <meta property="og:locale" content="" />
      <meta property="og:video" content="" />
      <meta property="og:video:url" content="" />

      <meta property="og:video:secure_url" content="" />
      <meta property="og:video:type" content="" />
      <meta property="og:video:width" content="" />
      <meta property="og:video:height" content="" />
      <meta property="og:image" content="" />
      <meta property="og:image:url" content="" />
      <meta property="og:image:secure_url" content="" />
      <meta property="og:image:type" content="" />
      <meta property="og:image:width" content="" />
      <meta property="og:image:height" content="" />
      <meta property="fb:app_id" content="" />

      <meta name="twitter:card" content="" />
      <meta name="twitter:site" content="" />
      <meta name="twitter:creator" content="" />

      <link rel="canonical" href="https://oakfusion.com" />
      <meta lang="en" />
    </>
  )
}

import React from "react"
import FooterModule from "../../components/FooterModule"
import "./styles.css"
import CTA from "../../components/CTA"
import FacebookIconSVG from "../../assets/images/vector/components/facebook"
import LinkedInIconSVG from "../../assets/images/vector/components/linkedin"
import InstagramIconSVG from "../../assets/images/vector/components/instagram"

const Footer = ({}) => {
  return (
    <FooterModule>
      <div className="footer-columns">
        <address>Piłsudskiego 43, 50-032 Wrocław, Poland</address>
        <address>
          +48 797 743 064{" "}
          <a href="mailto:office@oakfusion.pl">office@oakfusion.pl</a>
        </address>
        <div className="footer-social-media-container">
          <CTA
            to="https://www.facebook.com/OakfusionSoftwareHouse/"
            Icon={FacebookIconSVG}
          />
          <CTA
            to="https://www.instagram.com/oakfusion/?hl=en"
            Icon={InstagramIconSVG}
          />
          <CTA
            to="https://www.linkedin.com/company/oakfusion/mycompany/"
            Icon={LinkedInIconSVG}
          />
        </div>
      </div>
    </FooterModule>
  )
}

export default Footer

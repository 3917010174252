import React from "react";
import { ISVGIcon } from "./svgIcon.types";

const InstagramIconSVG = ({ color, width, height }: ISVGIcon) => {
  return (
    <svg
      width={width + "px"}
      height={height + "px"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_54)">
        <path
          d="M23.1621 10.5815C23.0149 10.1826 22.78 9.82153 22.4749 9.52515C22.1785 9.21997 21.8176 8.98511 21.4185 8.83789C21.0947 8.71216 20.6084 8.5625 19.7126 8.52173C18.7437 8.47754 18.4531 8.46802 16 8.46802C13.5466 8.46802 13.2561 8.47729 12.2874 8.52148C11.3916 8.5625 10.905 8.71216 10.5815 8.83789C10.1824 8.98511 9.82129 9.21997 9.52515 9.52515C9.21997 9.82153 8.98511 10.1824 8.83765 10.5815C8.71191 10.9053 8.56226 11.3918 8.52148 12.2876C8.47729 13.2563 8.46777 13.5469 8.46777 16.0002C8.46777 18.4534 8.47729 18.7439 8.52148 19.7129C8.56226 20.6086 8.71191 21.095 8.83765 21.4187C8.98511 21.8179 9.21973 22.1787 9.5249 22.4751C9.82129 22.7803 10.1821 23.0151 10.5813 23.1624C10.905 23.2883 11.3916 23.438 12.2874 23.4788C13.2561 23.5229 13.5464 23.5322 15.9998 23.5322C18.4534 23.5322 18.7439 23.5229 19.7124 23.4788C20.6082 23.438 21.0947 23.2883 21.4185 23.1624C22.2197 22.8533 22.853 22.22 23.1621 21.4187C23.2878 21.095 23.4375 20.6086 23.4785 19.7129C23.5227 18.7439 23.532 18.4534 23.532 16.0002C23.532 13.5469 23.5227 13.2563 23.4785 12.2876C23.4377 11.3918 23.2881 10.9053 23.1621 10.5815ZM16 20.7178C13.3943 20.7178 11.282 18.6057 11.282 16C11.282 13.3943 13.3943 11.2822 16 11.2822C18.6055 11.2822 20.7178 13.3943 20.7178 16C20.7178 18.6057 18.6055 20.7178 16 20.7178ZM20.9043 12.1982C20.2954 12.1982 19.8018 11.7046 19.8018 11.0957C19.8018 10.4868 20.2954 9.99316 20.9043 9.99316C21.5132 9.99316 22.0068 10.4868 22.0068 11.0957C22.0066 11.7046 21.5132 12.1982 20.9043 12.1982Z"
          fill="#F8F8F8"
        />
        <path
          d="M19.0625 16C19.0625 17.6914 17.6914 19.0625 16 19.0625C14.3086 19.0625 12.9375 17.6914 12.9375 16C12.9375 14.3086 14.3086 12.9375 16 12.9375C17.6914 12.9375 19.0625 14.3086 19.0625 16Z"
          fill="#F8F8F8"
        />
        <path
          d="M29.6921 0H2.30786C1.0332 0 0 1.0332 0 2.30786V29.6921C0 30.9668 1.0332 32 2.30786 32H29.6921C30.9668 32 32 30.9668 32 29.6921V2.30786C32 1.0332 30.9668 0 29.6921 0V0ZM25.1321 19.7878C25.0876 20.7659 24.9321 21.4336 24.7051 22.0181C24.2278 23.2522 23.2522 24.2278 22.0181 24.7051C21.4338 24.9321 20.7659 25.0874 19.7881 25.1321C18.8083 25.1768 18.4954 25.1875 16.0002 25.1875C13.5049 25.1875 13.1921 25.1768 12.2122 25.1321C11.2344 25.0874 10.5664 24.9321 9.98218 24.7051C9.3689 24.4744 8.81372 24.1128 8.35474 23.6453C7.88745 23.1865 7.52588 22.6311 7.29517 22.0181C7.06812 21.4338 6.9126 20.7659 6.86816 19.7881C6.823 18.8081 6.8125 18.4951 6.8125 16C6.8125 13.5049 6.823 13.1919 6.86792 12.2122C6.91235 11.2341 7.06763 10.5664 7.29468 9.98193C7.52539 9.3689 7.88721 8.81348 8.35474 8.35474C8.81348 7.88721 9.3689 7.52563 9.98193 7.29492C10.5664 7.06787 11.2341 6.9126 12.2122 6.86792C13.1919 6.82324 13.5049 6.8125 16 6.8125C18.4951 6.8125 18.8081 6.82324 19.7878 6.86816C20.7659 6.9126 21.4336 7.06787 22.0181 7.29468C22.6311 7.52539 23.1865 7.88721 23.6455 8.35474C24.1128 8.81372 24.4746 9.3689 24.7051 9.98193C24.9324 10.5664 25.0876 11.2341 25.1323 12.2122C25.177 13.1919 25.1875 13.5049 25.1875 16C25.1875 18.4951 25.177 18.8081 25.1321 19.7878Z"
          fill="#F8F8F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_54">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramIconSVG;

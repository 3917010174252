import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const useCarouselImages = () => {
  const carouselImages = useStaticQuery(graphql`
    query carouselImagesQuery {
      allFile(
        filter: { name: { in: ["card1", "card2", "card3", "card4", "card5"] } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return carouselImages;
};

export default useCarouselImages;

import React from "react"
import Module from "../../components/Module"
import "./styles.css"
import Carousel from "../../components/Carousel/Carousel"
import ArrowDownIcon from "../../assets/images/vector/components/arrowDown"
import useTawk from "../../../hooks/useTawk"

const Landing = () => {
  const { onInteractClickHandler } = useTawk()

  return (
    <Module>
      <div className="landing__container">
        <h1>Meet your team</h1>
        <p className="landing-text">
          Work on your product with our team of specialists
        </p>
        <Carousel />
        <div className="contact-button" onClick={onInteractClickHandler}>
          <ArrowDownIcon /> <span>Contact</span>
        </div>
      </div>
    </Module>
  )
}

export default Landing

import React, { PropsWithChildren } from "react";
import "./styles.css";

const Module = ({ children }: PropsWithChildren<{}>) => {
  return (
    <section className="module__container">
      <div className="module__content">{children}</div>
    </section>
  );
};

export default Module;
